import React from "react"
import PageHeader from "../../components/common/PageHeader"
import StockChart from "../../components/financial/StockChart"
import { useStaticQuery, graphql } from "gatsby"
import StockTable from "../../components/financial/StockTable"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import HeaderFeaturedImage from "../../components/common/HeaderFeaturedImage"

export default function StockInformationPage() {
  const data = useStaticQuery(graphql`
    query {
      wpPage(databaseId: { eq: 58 }) {
        content
        title
        topShareholders {
          shareholdersTable
        }
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `)
  const { title, featuredImage, content, topShareholders } = data.wpPage
  return (
    <Layout>
      <SEO title="Stock Information" />
      <div>
        {featuredImage && (
          <HeaderFeaturedImage featuredImage={featuredImage} title={title} />
        )}
        <div
          id="financial-reports"
          className="max-w-6xl mx-auto py-8 lg:py-16 px-4 lg:px-8"
        >
          <div className="flex flex-col gap-4 lg:gap-8">
            <div>
              <div
                dangerouslySetInnerHTML={{ __html: content }}
                className="wp-content"
              />
            </div>
            <div className="flex flex-col gap-8">
              <PageHeader text="Stock Information" />
              <StockChart />
              <StockTable />
            </div>
            <div id="top-shareholders">
              <div className="text-2xl text-primary mb-4">Top shareholders</div>
              <div
                dangerouslySetInnerHTML={{
                  __html: topShareholders?.shareholdersTable,
                }}
                className="wp-content"
              />
            </div>
            <div className="flex flex-col gap-4">
              <div className="text-xl lg:text-2xl text-primary">
                Certified Adviser
              </div>
              <p>The company has chosen Amudova AB as it's certified adviser</p>
              <p>
                Amudova AB
                <br />
                Tel: 08-545 01758
                <br />
                E-mail: info@amudova.se
                <br />
                Nybrogatan 6<br />
                Box 5855
                <br />
                SE-102 40 Stockholm
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

import React, { useState, useEffect } from "react"
const isBrowser = () => typeof window !== "undefined"

export default function StockChart() {
  useEffect(() => {
    if (typeof window !== `undefined`) {
      ;(function (widgets) {
        var url = "https://widget.datablocks.se/api/rose"
        if (!window._MF) {
          var b = document.createElement("script")
          b.type = "text/javascript"
          b.async = true
          b.src = url + "/assets/js/loader-v4.js"
          document.getElementsByTagName("body")[0].appendChild(b)
        }
        window._MF = window._MF || {
          data: [],
          url: url,
          ready: !!0,
          render: function () {
            window._MF.ready = !0
          },
          push: function (w) {
            window._MF.data.push(w)
          },
        }
        window._MF.push(widgets)
      })([
        {
          query: "#stock-chart",
          widget: "stock-chart",
          locale: "en",
          token: "aeb7d408-da4c-44d8-81d7-35b2f90ad380",
        },
      ])
    }
  }, [])
  return (
    <div>
      <div className="mfn-widget">
        <div className="text-base">
          <div id="stock-chart"></div>
        </div>
      </div>
    </div>
  )
}

import React, { useState, useEffect } from "react"

export default function StockTable() {
  const [widget, setWidget] = useState()
  useEffect(() => {
    // get data from GitHub api

    fetch(
      "https://widget.datablocks.se/api/rose/widgets/stock-table?token=77da8a57-9b09-4fd8-882e-4175cb8acd5a"
    )
      .then(function (response) {
        // The API call was successful!
        return response.text()
      })
      .then(function (html) {
        // This is the HTML from our response as a text string

        setWidget(html)
      })

      .catch(function (err) {
        // There was an error
        console.warn("Something went wrong.", err)
      })
  }, [])

  const result = widget

  return (
    <div>
      <div className="mfn-widget" id="stock-table">
        <div className="text-base  overflow-x-scroll md:overflow-x-auto">
          <div dangerouslySetInnerHTML={{ __html: result }} />
        </div>
      </div>
    </div>
  )
}
